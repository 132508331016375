CookieConsent.run({
    guiOptions: {
        consentModal: {
            layout: "box",
            position: "bottom right",
            equalWeightButtons: true,
            flipButtons: false
        },
        preferencesModal: {
            layout: "box",
            position: "right",
            equalWeightButtons: true,
            flipButtons: false
        }
    },
    onConsent: function(){
        if(CookieConsent.acceptedCategory('analytics')){

        console.log('onConsent fired ...');
        function gtag() { dataLayer.push(arguments); }

        gtag('consent', 'update', {
        ad_user_data: 'granted',
        ad_personalization: 'granted',
        ad_storage: 'granted',
        analytics_storage: 'granted'
        });
        
        // Load gtag.js script.
        var gtagScript = document.createElement('script');
        gtagScript.async = true;
        gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-J44JELWTCF';
        var firstScript = document.getElementsByTagName('script')[0];
        firstScript.parentNode.insertBefore(gtagScript,firstScript);
        
        }},
    categories: {
        necessary: {
            readOnly: true,
            enabled: true
        },
        functionality: {},
        analytics: {
            autoClear: {
                cookies: [
                    {
                        name: /^(_li|_ga|_gid|_gat)/
                    }
                ]
            }
        }
    },
    language: {
        default: "de",
        autoDetect: "browser",
        translations: {
            de: {
                consentModal: {
                    title: " Wir verwenden Cookies!",
                    description: "Guten Tag, wir bei Webappear verwenden auf unserer Webseite essentielle Cookies, um ihren ordnungsgemäßen Betrieb zu gewährleisten, und Tracking-Cookies, um zu verstehen, wie Sie mit ihr interagieren. Letztere werden nur nach Zustimmung gesetzt. Dienste auswählen",
                    closeIconLabel: "",
                    acceptAllBtn: "Alle akzeptieren",
                    acceptNecessaryBtn: "Alle ablehnen",
                    showPreferencesBtn: "Einstellungen verwalten",
                    footer: "<a href=\"https://www.webappear.de/allgemein/rechtliche-angaben/datenschutz\">Datenschutz</a>"
                },
                preferencesModal: {
                    title: "Präferenzen für die Zustimmung",
                    closeIconLabel: "Modal schließen",
                    acceptAllBtn: "Alle akzeptieren",
                    acceptNecessaryBtn: "Alle ablehnen",
                    savePreferencesBtn: "Einstellungen speichern",
                    serviceCounterLabel: "Dienstleistungen",
                    sections: [
                        {
                            title: "Streng Notwendige Cookies <span class=\"pm__badge\">Immer Aktiviert</span>",
                            description: "Diese Cookies sind für das reibungslose Funktionieren der Website unerlässlich und können nicht deaktiviert werden.",
                            linkedCategory: "necessary"
                        },
                        {
                            title: " \t\nAnalytische Cookies",
                            description: "Diese Cookies sind für die analytischen Funktionen der Webseite zuständig.",
                            linkedCategory: "analytics",
                            cookieTable: {
                                caption: 'Cookie table',
                                headers: {
                                    name: 'Cookie',
                                    domain: 'Domain',
                                    desc: 'Description'
                                },
                                body: [
                                    {
                                        name: '_li_id',
                                        domain: 'Leadinfo.com',
                                        desc: '_li_id wird zwei Jahre gespeichert',
                                    },
                                    {
                                        name: '_li_ses',
                                        domain: 'Leadinfo.com',
                                        desc: '_li_ses wird nur für die aktuelle Sitzung verwendet',
                                    },
                                    {
                                        name: '_ga',
                                        domain: 'google.com',
                                        desc: 'Used to distinguish users, expires in 2 years'
                                    },
                                    {
                                        name: '_gid',
                                        domain: 'google.com',
                                        desc: 'Used to distinguish users, expires in 24 hours'
                                    },
                                    {
                                        name: '_gat',
                                        domain: 'google.com',
                                        desc: 'Used to throttle request rate, expires in 1 minute'
                                    }
                                ]
                            }
                        }
                    ]
                }
            }
        }
    }
});